import Config from '../classes/Config';
import PlayGame from './PlayGame';
import GameButton from '../classes/GameButton';
import PopInText from '../classes/PopInText';
import _ from 'lodash';
import { ActionSheet, ActionSheetButtonStyle } from '@capacitor/action-sheet';
import CircleMaskImage from 'phaser3-rex-plugins/plugins/circlemaskimage.js';


export default class GameSelection extends Phaser.Scene {
  constructor() {
    super({
      key: 'GameSelection'
    });
  }

  // these get loaded in Intro.js
  static games = [
    { name: 'Dogpile', key: `dogpile_game_select`, available: false },
    { name: 'Avalanche', key: `avalanche_game_select`, available: true },
    { name: 'Poker', key: `poker_game_select`, available: false }
  ];

  selectedBorderColor = '#ffca3a' // '0xffca3a'

  panels = {}
  buttons = {}

  getWidth() {
    // console.log('getWidth', this.cameras.main.width);
    return this.cameras.main.width;
  }

  getHeight() {
    return this.cameras.main.height;
  }

  preload() {}

  create() { // beginning of game where everything is populated
    let self = this;
    this.game.setPositionConfig(this);
    if (!this.scene.get('PlayGame')) {
      this.scene.add('PlayGame', PlayGame, false);
    }
    window.gg = this;
    this.input.topOnly = false;
    self.game.dnrConfig = new Config();
    // background graphic
    this.bgContainer = new Phaser.GameObjects.Container(this,0,0);
    this.bgContainer.setDepth(-1000001);
    this.add.existing(this.bgContainer);
    this.game.setGameTheme(this.game.gameTheme, this);
    this.game.setGameSpeed(this.game.gameSpeed);

    // this.setupSettingsButton();

    this.game.setHumanPlayer(this.game.gameAvatar);

    // this.game.getDnrToken();

    this.playerInfo = this.game.setupPlayerInfo(this);
    this.game.gameCurrentLevelProgress = 100 * (this.game.gamePointsInCurrentLevel / (this.game.gameCurrentLevel * PlayGame.levelUpFactor));
    this.playerInfo.levelProgressBar.setProgress({perc: this.game.gameCurrentLevelProgress});

    // let decknrollLogo = new Phaser.GameObjects.Image(this, this.cameras.main.width / 2, 25, 'decknroll_logo');
    // decknrollLogo.setOrigin(0.5, 0);
    // decknrollLogo.setScale(1.5);
    // this.add.existing(decknrollLogo);  

    this.deck64Logo = new Phaser.GameObjects.Image(this, this.cameras.main.width / 2, 25, 'deck64_logo');
    this.deck64Logo.setOrigin(0.5, 0);
    this.deck64Logo.setScale(.2);
    this.deck64Logo.setDepth(100000000);
    this.add.existing(this.deck64Logo); 

    ////// games
    this.createGamesPanel = () => {
      this.createTable({
        scene: this, data: GameSelection.games, key: 'games', rows: this.game.positionConfig.gameSelectionPanel.rows, scrollMode: this.game.positionConfig.gameSelectionPanel.scrollMode,  height: this.game.positionConfig.gameSelectionPanel.height, textKey: 'name', fileKey: 'key', showTextLabel: false, loadCallback: this.gameLoadCallback,
        clickCallback: this.gameClickCallback, showSlider: false, width: this.game.positionConfig.gameSelectionPanel.width, scaleIconsTo: 1, x: this.game.positionConfig.gameSelectionPanel.x
      });
    }
    this.createGamesPanel();

    this.panels.games.setOrigin(0.5);

    const updateGameSize = () => {
      // console.log("GameSelection scene: updateGameSize called", this)
      // reposition elements and change gameSize
      this.time.delayedCall(1, ()=>{
        // console.log('delayedCall1', this.game.getMainWidth());
        this.game.setGameSize(this, (scene) => {
          // console.log('delayedCall2', this.game.getMainWidth());
          // console.log('callback of sGZ', scene.game.positionConfig.playerInfo.x, scene.game.positionConfig.playerInfo.y);
          scene.playerInfo.setPosition(scene.game.positionConfig.playerInfo.x, scene.game.positionConfig.playerInfo.y);
          scene.deck64Logo.setPosition(scene.game.positionConfig.deck64logo.x, scene.game.positionConfig.deck64logo.y);
          scene.panels['games'].destroy();
          scene.createGamesPanel();
          // console.log('bgContainer###', scene.bgContainer.scale, scene.bgContainer.list[0].scale)
          scene.bgContainer.setX(0);
          if (!window.showLandscape()) { // change the background for portrait mode
            // console.log('!window.showLandscape()', !window.showLandscape(), scene.bgContainer)
            scene.bgContainer.setRotation(Math.PI / 2);
            scene.bgContainer.list[0].setOrigin(0, 1);
          }
          else {
            scene.bgContainer.list[0].setOrigin(0, 0);
            scene.bgContainer.setRotation(0);
          }
          scene.bgContainer.list[0].setScale(window.showLandscape() ? Math.max(scene.getWidth() / scene.bgContainer.list[0].width, scene.getHeight() / scene.bgContainer.list[0].height) :
            Math.max(scene.getWidth() / scene.bgContainer.list[0].height, scene.getHeight() / scene.bgContainer.list[0].width));
          // console.log('getWidth(), img width, img height, innerWidth, innerHeight', scene.getWidth(), scene.bgContainer.list[0].width, scene.bgContainer.list[0].height, window.innerWidth, window.innerHeight)
          // console.log('ASDFASDF updateGameSize', window.showLandscape(), scene.getWidth() / scene.bgContainer.list[0].width, scene.getWidth() / scene.bgContainer.list[0].height)
        });
        
      });
    }

    this.scale.on('orientationchange', updateGameSize);
    window.addEventListener('resize', updateGameSize);
    updateGameSize();
  }

  setupSettingsButton() {
    const btnSize = 150;
    // ⚙
    this.settingsBtn = new GameButton({scene: this,
      x: 45,
      y: 45,
      w: btnSize / 2 + 10,
      h: btnSize / 2 + 10,
      text: `⚙`,
      font: "115px Arial",
      radius: 45,
      borderWidth: 0,
      bgAlpha: 0.001,
      textShadow: '#000000',
      clickFunction: ()=>{
        this.scene.get('Settings').input.topOnly = false;
        this.scene.run('Settings');
        this.scene.setVisible(true, 'Settings');
      },
      clickFunctionData: {},
    });
  }

  updateAvatar(keyRef) {
    this.playerInfo.avatar.setTexture(keyRef);
  }

  ////// game
  gameLoadCallback(scene, label) {
    // if (label.name == this.game.gameDeckFace) {
    //   this.selectGameChild(label);
    // }
    // probably isn't needed now
  }
  gameClickCallback(child) {
    // console.log('click', child);
    // this.scene.scene.tweens.chain({
    //   targets: [this],
    //   tweens: [{
    //       scale: .1,
    //       // y: -200,
    //       ease: 'Expo',
    //       duration: 330,
    //       repeat: 0,
    //       yoyo: false,
    //       onComplete: ()=>{
    //         this.scene.start('PlayGame', child.name);
    //       }
    //     }]
    // }).play();
    // console.log('gCC', this.scene, this.scene.tweens)
    // TODO do nothing with current scene (GameSelection)? It should be paused anyway when PlayGame starts.
    this.scene.start('PlayGame', child.name).stop('GameSelection'); // .remove('GameSelection');
    // this.scene.start('PlayGame', child.name);
    // if ((this.loadingMessage && !this.loadingMessage.visible) || !this.loadingMessage) {
      // this.loadingMessage = new PopInText({
      //   scene: this,
      //   x: this.getWidth() / 2,
      //   y: this.getHeight() / 2,
      //   text: 'Loading Deck...',
      //   box: {w: 600, h: 300, radius: 30},
      //   startScale: 1,
      //   endScale: 1.15,
      //   scaleBy: 1.15,
      //   duration: 50,
      //   onComplete: ()=>{
      //     //
      //   },
      //   font: "56px Arial Black",
      // });
    // }
  }
  selectGameChild(child) {
    // if (this.selectedDeck && this.selectedDeck.getElement("background")) {
    //   this.selectedDeck.getElement("background").setFillStyle(0xffffff);
    // }
    // child.getElement("background").setFillStyle(PlayGame.COLOR_GOLD);
    // this.selectedDeck = child;
    // this.subPanels['deck'].scrollToChild(child); // not working?
  }

  ////// shared functions
  createIcon = function (scene, item, textKey, fileKey, loadCallback, scaleIconsTo, showTextLabel) {
    const icon = (fileKey) ? new CircleMaskImage(scene, 0, 0, item[fileKey], {
        maskType: 'roundRectangle',
        radius: 10,
        strokeColor: this.selectedBorderColor, //'gray',
        strokeWidth: 6,
      }) : '';
      if (icon != '') {
        scene.add.existing(icon);
      }
      icon.setName(item[textKey]);
      icon.setScale(scaleIconsTo);
      icon.setInteractive().on('pointerdown', function(pointer, localX, localY, event){
          // console.log('down', icon);
          icon.setScale(scaleIconsTo - .1)
      });
      icon.setInteractive().on('pointerup', function(pointer, localX, localY, event){
        // console.log('down', icon);
        icon.setScale(scaleIconsTo)
      });
      if (!item.available) {
        icon.setAlpha(0.65);
      }
      return icon;
  };

  createTable({ scene, data, key, rows = 1, columns = 10, textKey, fileKey, showTextLabel = true, loadCallback, scrollMode = 0, clickCallback, width = 1, height = 730, showTitle = false, showSlider = true, x = 50, y = 130, scaleIconsTo = 1, singleValue = null }) {
    let self = this;
    const keyAsCamel = _.camelCase(key);
    const keyAsPascal = keyAsCamel.charAt(0).toUpperCase() + keyAsCamel.slice(1);
    let scrollablePanel = scene.rexUI.add.scrollablePanel({
      x: x,
      y: y,
      name: key,
      width: width,
      height: height,
      scrollMode: scrollMode,
      background: null, // scene.rexUI.add.roundRectangle(0, 0, 2, 2, 10, 0xffffff),
      panel: {
        child: scene.rexUI.add.fixWidthSizer({
          space: {
            left: 10,
            right: 10,
            top: 15,
            bottom: 3,
            item: 8,
            line: 8,
          }
        }).enableLayoutWarn(false),
        width: width - 100, // was width / 2 which worked as well
        mask: {
          mask: true,
          padding: { right: -15, },
          updateMode: 'everyTick'
        }
      },
      scroller: {},
      slider: showSlider ? {
        track: scene.rexUI.add.roundRectangle(0, 0, 20, 10, 10, PlayGame.COLOR_LIGHT),
        thumb: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 20, PlayGame.COLOR_GOLD),
      } : {},
      space: {
        left: 10,
        right: 10,
        top: 8,
        bottom: 8,
        panel: 8,
      }
    }).enableLayoutWarn(false).setOrigin(0).layout();
    this.panels[key] = scrollablePanel;
    var items = data;
    //var columns = Math.ceil(items.length / rows);
    // TODO look at moving this to another function so we can re-use it more easily to update a specific panel such as when changing face card we can update face back options
    var table = scene.rexUI.add.gridSizer({
      column: columns,
      row: rows,
      rowProportions: 1,
      space: { column: 15, row: 15 },
      name: key  // Search this name to get table back
    }).enableLayoutWarn(false);//.setDepth(10000003).enableLayoutWarn(false);
    // table.setChildrenInteractive().on('child.click', clickCallback, this);
    var item, r, c;
    table.shake(600, 10);
    if (singleValue) {
      scrollablePanel.setChildrenInteractive().on('child.click', clickCallback, this);
      // these down and up events are not working
      // .on('child.down', (child) => {
      //   child.setScale(0.8);
      // }, this)
      // .on('child.up', (child) => {
      //   child.setScale(1);
      // })
      let bgColor = 0xffffff;
      if (singleValue < 1) {
        singleValue = PlayGame.BASE_VALUES[singleValue];
      }
      let fontSize = 110;
      if (!Number(singleValue)) { // if it's not a number
        fontSize = 70;
      }
      var label = scene.rexUI.add.label({
        background: scene.rexUI.add.roundRectangle(0, 0, 0, 0, 14, bgColor),
        orientation: 'y',
        icon: '',
        text: scene.add.text(0, 0, singleValue, { font: `${fontSize}px Arial`, color: "#000000" }),
        name: `number${keyAsPascal}`,
        space: { 
          left: 80,
          right: 1,
          top: 70,
          bottom: 50,},
      });
      table.add(label, 0, 0, 'top', 0, true);
      this[`${keyAsCamel}TextObj`] = label.children[1];
    }
    else {
      table.setChildrenInteractive().on('child.click', clickCallback, this);
      for (var i = 0, cnt = items.length; i < cnt; i++) {
        item = items[i];
        r = i % rows;
        c = (i - r) / rows;
        table.add(
          this.createIcon(scene, item, textKey, fileKey, loadCallback, scaleIconsTo, showTextLabel),
          c,
          r,
          'top',
          0,
          true
        );
      }
    }
    scrollablePanel.getElement('panel').addBackground(
      scene.rexUI.add.roundRectangle(0, 0, 0, 0, 0, undefined)
    );
    if (showTitle) {
      var capKey = key.charAt(0).toUpperCase() + key.slice(1);
      var title = scene.rexUI.add.label({
        width: width - 100,
        orientation: 'x',
        text: scene.add.text(0, 0, capKey, { font: "32px Arial", color: "#000000" }),
      });//.setDepth(10000006);
      scrollablePanel.getElement('panel').add(
          title, // child
          0, // proportion
          'left', // align
          0, // paddingConfig
          true // expand
        );
    }
    scrollablePanel.getElement('panel').add(
      table, // child
      1, // proportion
      'left', // align
      0, // paddingConfig
      true // expand
    );
    scrollablePanel.layout();
    return scrollablePanel;
  }

  update() { }
}
